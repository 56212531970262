export const bannerType = [
  { label: 'casinoBannerManagement.constant.homeBackground', value: 'homeBackground' },
  { label: 'casinoBannerManagement.constant.homeBanner', value: 'homeBanner' },
  { label: 'casinoBannerManagement.constant.casinoBackground', value: 'casinoBackground' },
  { label: 'casinoBannerManagement.constant.casinoBanner', value: 'casinoBanner' },
  { label: 'casinoBannerManagement.constant.promotionBackground', value: 'promotionsBackground' },
  { label: 'casinoBannerManagement.constant.promotionBanner', value: 'promotionsBanner' },
  // { label: 'casinoBannerManagement.constant.loyaltyBackground', value: 'loyaltyBackground' },
  // { label: 'casinoBannerManagement.constant.loyaltyBanner', value: 'loyaltyBanner' }
]


export const pageNameOptions = [
  { label: 'casinoBannerManagement.constant.lobbySlider', value: 'lobbySlider'},
  { label: 'casinoBannerManagement.constant.lobbyPage', value: 'lobbyPage' },
  { label: 'casinoBannerManagement.constant.promotionPage', value: 'promotionPage' },
  { label: 'casinoBannerManagement.constant.bonusPromotionPage', value: 'bonusPromotionPage' },
  { label: 'casinoBannerManagement.constant.tournamentPage', value: 'tournamentPage' },
  { label: 'casinoBannerManagement.constant.affiliatePage', value: 'affiliatePage' },
  // { label: 'casinoBannerManagement.constant.personalBonus', value: 'personalBonus' },
  { label: 'casinoBannerManagement.constant.buyPage', value: 'buyPage' },
  { label: 'casinoBannerManagement.constant.referAfriend', value: 'referAfriend' },
]

export const tableHeaders = [
  { labelKey: 'casinoBannerManagement.headers.id', value: 'pageBannerId' },
  { labelKey: 'casinoBannerManagement.headers.name', value: 'name' },
  { labelKey: 'casinoBannerManagement.headers.bannerMobile', value: 'mobileImageUrl' },
  { labelKey: 'casinoBannerManagement.headers.bannerDesktop', value: 'desktopImageUrl' },
  // { labelKey: 'casinoBannerManagement.headers.status', value: 'status' },
  { labelKey: 'casinoBannerManagement.headers.pageName', value: 'pageName' },
  { labelKey: 'casinoBannerManagement.headers.action', value: '' },
];