import React, { useState } from 'react'
import { Table, Button } from '@themesberg/react-bootstrap'
import { useTranslation } from 'react-i18next'
import { InlineLoader } from '../Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../Pagination'
import { TRANSACTION_STATUS, tableHeaders } from './constants'
import { getDateTime } from '../../utils/dateFormatter'
import { Link } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from '../Toast'
import Trigger from '../OverlayTrigger'
import ModalView from '../Modal'
import RemarksModal from '../../pages/PlayerDetails/components/Verification/RemarksModal'
import { usePaymentRefundMutation } from '../../reactQuery/hooks/customMutationHook'
import {convertToTimeZone, getFormattedTimeZoneOffset} from '../../utils/helper'
import { getItem } from '../../utils/storageUtils'
import { timeZones } from '../../pages/Dashboard/constants'
import { MoreDetail } from '../ConfirmationModal'
const BankingTransactionsList = ({
  page,
  setLimit,
  limit,
  setPage,
  totalPages,
  loading,
  data,
  isAllUser,
  transactionRefetch,
}) => {
  const { t } = useTranslation('players')
  const [paymentTransactionId, setPaymentTransactionId] = useState(null)
  const [transactionBankingId, setTransactionBankingId] = useState(null)
  const [show, setShow] = useState(false);
  const [moreDetailData, setMoreDetailData] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [userId, setUserId] = useState(false)
  const timeZone = getItem("timezone");
  const timezoneOffset =  timeZone != null ? timeZones.find(x=> x.code === timeZone).value : getFormattedTimeZoneOffset()

  const toggleModalForRefund = (actioneeId, paymentTransactionId, transactionBankingId) => {
    setUserId(actioneeId)
    setPaymentTransactionId(paymentTransactionId);
    setTransactionBankingId(transactionBankingId);
    toggleModal();
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  const { mutate: refundPayment, isLoading : refundLoading } = usePaymentRefundMutation({
    onSuccess: (data) => {
      if (data.data.message) {
        toast(data.data.message, 'success');
         transactionRefetch()
        closeModal();
      } else {
        toast(data.data.message, 'error');
      }
    }
  });

  const onSubmit = (dataValue) => {
    const data = {
      userId,
      reason: dataValue.reason,
      paymentTransactionId,
      transactionBankingId,
    };
    refundPayment(data);
  };


  const handleShowMoreDetails = (details) => {
    if (details) {
      try {
        const parsedDetails = typeof details === 'string' ? JSON.parse(details) : details;
        setMoreDetailData(parsedDetails);
      } catch (e) {
        setMoreDetailData(null);
      }
    } else {
      setMoreDetailData(null);
    }
    setShow(true);
  };


  return (
    <>
     <ModalView
        openModal={openModal}
        toggleModal={toggleModal}
        size='lg'
        hideHeader
        center
        className='announcement-view-wrap'
        firstBtnClass='btn-primary'
        secondBtnClass='btn-secondary'
        hideFooter
      >
        <RemarksModal closeModal={closeModal} onSubmit={onSubmit} loading={refundLoading} />
      </ModalView>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders(isAllUser)?.map((h, idx) => (
              <th
                key={idx}
                style={{
                  cursor: 'pointer'
                }}
                className=''
              >
                {t(h.labelKey)}{' '}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data &&
            data?.rows?.map(
              ({
                actioneeName,
                actioneeEmail,
                actioneeId,
                transactionId,
                amount,
                gcCoin,
                scCoin,
                transactionType,
                status,
                createdAt,
                userId,
                moreDetails
              }) => {
                return (
                  <tr key={transactionId} className='text-start'>
                    <td>{transactionId}</td>
                    {!isAllUser ? <td>{actioneeName}</td> : <td><Link to={`/admin/player-details/${userId}`}>{actioneeEmail}</Link></td>}
                    <td>{amount?.toFixed(2)}</td>
                    <td>{gcCoin ? gcCoin : '-'}</td>
                    <td>{scCoin ? scCoin : '-'}</td>
                    <td className='text-capitalize'> {transactionType}</td>
                    <td>{TRANSACTION_STATUS[status]}</td>
                    <td>{getDateTime(convertToTimeZone(createdAt, timezoneOffset))}</td>
                    <td><Button style={{padding:'3px 8px'}} onClick={() => handleShowMoreDetails(moreDetails)}>More Details</Button></td>
                   {/* {(transactionType === 'deposit' && status === 1)  ?
                    <td><Button type='button' className='btn btn-success'                    
                     onClick = {()=> toggleModalForRefund(actioneeId,paymentTransactionId, transactionBankingId)}>Void/Refund</Button></td>
                      :
                      <td> - </td>} */}
                  </tr>
                )
              }
            )}

          {data?.count === 0 &&
            (
              <tr>
                <td
                  colSpan={9}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
            )}
        </tbody>
      </Table>
      {loading && <InlineLoader />}
      {data?.count !== 0 &&
        (
          <PaginationComponent
            page={data?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />

        )}

        <MoreDetail
        show={show}
        setShow={setShow}
        moreDetailData={moreDetailData}
        />
    </>
  )
}

export default BankingTransactionsList