import { Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { actionConstants, activityConstants, statusConstants, transactionConstants } from "../../constants";
import { formatDateYMD, getDateThreeMonthsBefore, getStartDateMonthsAgo } from "../../../../utils/dateFormatter";
import Trigger from "../../../../components/OverlayTrigger";
import { toast } from "../../../../components/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { convertTimeZone, getFormattedTimeZoneOffset, onDownloadCsvClick } from "../../../../utils/helper";
import DateRangePicker from "../../../../components/DateRangePicker";
import { timeZones } from "../../../Dashboard/constants";
import { getItem } from "../../../../utils/storageUtils";
import DatePicker from "react-datepicker";
import Datetime from 'react-datetime'


const ActivityTableFilters = ({
  startDate, setStartDate,
  endDate, setEndDate,
  providerName,
  setProviderName,
  transaction,
  setTransaction,
  coinType,
  setCoinType,
  action,
  setAction,
  data,
  resetFilters,
  casinoProvidersData,
  providerNameArray,
  setProviderNameArray,
  getCsvDownloadUrl
}) => {

  const handleDownloadClick = async () => {
    try {
      const url = getCsvDownloadUrl();
      await  onDownloadCsvClick(url, 'User_Activity')
    } catch (error) {
      console.error('Error downloading CSV:', error);
    } 
  };
  return (
    <Row>
      {/* <Col xs="12" sm="6" lg="3" className="mb-3">
        <Form.Label column="sm" className="mx-auto text-nowrap px-2">
          Time Period
        </Form.Label>
        <DateRangePicker width="auto" state={state} setState={setState} timeZoneCode={timezoneOffset} startDate = {startDate} endDate={endDate}/>
      </Col> */}
       <Col
            className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
          >
            <Form.Label column="sm" className="mx-auto text-nowrap px-2">
              Start Date
            </Form.Label>
            <Datetime
              value={startDate}
              onChange={(date) => setStartDate(date)}
              timeFormat={false}
            />
          </Col>
          <Col
            className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
          >
            <Form.Label column="sm" className="mx-auto text-nowrap px-2">
              End Date
            </Form.Label>
            <Datetime
              value={endDate}
              timeFormat={false}
              onChange={(date) => setEndDate(date)}
            />
          </Col>
      <Col xs="auto" className="mb-3">
        <Form.Label>Actions</Form.Label>
        <Form.Select value={action} onChange={(e) => setAction(e.target.value)}>
          {actionConstants?.map(({ label, value }) => {
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </Form.Select>
      </Col>

      <Col xs="auto" className="mb-3">
        <Form.Label>Transaction</Form.Label>
        <Form.Select
          value={transaction}
          onChange={(e) => setTransaction(e.target.value)}
        >
          {transactionConstants?.map(({ label, value }) => {
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </Form.Select>
      </Col>

      <Col xs="auto" className="mb-3">
        <Form.Label>Coin Type</Form.Label>
        <Form.Select
          value={coinType}
          onChange={(e) => setCoinType(e.target.value)}
        >
          <option value="">All</option>
          <option value="GC">Gold Coins</option>
          <option value="SC">Sweep Coins</option>
        </Form.Select>
      </Col>
      {casinoProvidersData?.rows && casinoProvidersData?.rows.length > 0 ? (
        <>
          <Col xs="auto" className="mb-3">
            <Form.Label>Provider</Form.Label>
            <Form.Select
              value={providerName}
              onChange={(e) => setProviderName(e.target.value)}
            >
              {providerNameArray?.map(({ lable, value }) => {
                return (
                  <option key={value} value={value}>
                    {lable}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
        </>
      ) : (
        <></>
      )}
      <Col
        xs="auto"
        className="d-flex justify-content-center align-items-end mb-3"
      >
        <Trigger message="Download as CSV" id={"csv"} />
        <Button
          id={"csv"}
          variant="success"
          disabled={data?.count === 0}
          onClick={handleDownloadClick}
        >
          <FontAwesomeIcon icon={faFileDownload} />
        </Button>
      </Col>

      <Col
        xs="auto"
        className="d-flex justify-content-center align-items-end mb-3"
      >
        <Trigger message="Reset Filters" id={"redo"} />
        <Button id={"redo"} variant="success" onClick={resetFilters}>
          <FontAwesomeIcon icon={faRedoAlt} />
        </Button>
      </Col>
    </Row>
  );
};

export default ActivityTableFilters;
