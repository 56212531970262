import adminSignInEN from './adminSignIn.json'
import sidebarEN from './sidebar.json'
import notfoundEN from './notFound.json'
import playersEN from './players.json'
import translationEN from './translation.json'
import packagesEN from './packages.json'
import cmsEN from './cms.json'
import imageGalleryEN from './imageGallery.json'
import staffEN from './staff.json'
import emailTemplateEN from './emailTemplate.json'
import profileEN from './profile.json'
import countriesEN from './countries.json'
import casinoGamesEN from './casino-games.json'
import casinoEN from './casino.json'
import bonusEN from './bonus.json'
import dashboardEN from './dashboard.json'
import verificationEN from './verification.json'
import contentPagesEN from './contentPages.json'
import tournaments from './tournaments.json'
import tier from './tier.json'
import referralBonusEN from './referralBonus.json'



const translationsEN = {
  adminSignIn: adminSignInEN,
  sidebar: sidebarEN,
  notFound: notfoundEN,
  players: playersEN,
  translation: translationEN,
  packages: packagesEN,
  cms: cmsEN,
  imageGallery: imageGalleryEN,
  staff: staffEN,
  emailTemplate: emailTemplateEN,
  profile: profileEN,
  countries: countriesEN,
  casino: casinoEN,
  casinoGames: casinoGamesEN,
  bonus: bonusEN,
  dashboard: dashboardEN,
  verification: verificationEN,
  contentPages : contentPagesEN,
  tournaments :tournaments,
  tier:tier,
  referralBonus: referralBonusEN

}

export default translationsEN