import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateYMD, getDateThreeMonthsBefore } from '../../../utils/dateFormatter';
import { getReports } from '../../../utils/apiCalls';
import { convertTimeZone, convertToUtc, getFormattedTimeZoneOffset } from '../../../utils/helper';
import { timeZones } from '../constants';
import { getItem, setItem } from '../../../utils/storageUtils';

const useDashboardDataListing = (economicDataAccordionOpen = false, transactionDataAccordianOpen = false) => {
  const { t } = useTranslation(['dashboard']);
  const currentTimeZone = getItem("timezone");
  const currentTimezoneOffset = timeZones?.find(x => x.code === currentTimeZone)?.value;
  const timeZoneOffset = getFormattedTimeZoneOffset();
  const [playerType, setPlayerType] = useState('real');
  const [timeStamp, setTimeStamp] = useState(currentTimezoneOffset ? currentTimezoneOffset : timeZoneOffset);
  const [timeZoneCode, setTimeZoneCode] = useState(timeZones.find(x => x.value === timeStamp)?.code);

  const [startDate, setStartDate] = useState(convertTimeZone(getDateThreeMonthsBefore(), timeZoneCode));
  const [endDate, setEndDate] = useState(convertTimeZone(new Date(), timeZoneCode));

  useEffect(() => {
    setTimeZoneCode(timeZones.find(x => x.value === timeStamp)?.code);
    setItem("timezone", timeZones.find(x => x.value === timeStamp)?.code);
  }, [timeStamp]);

  useEffect(() => {
    setStartDate(convertTimeZone(getDateThreeMonthsBefore(), timeZoneCode));
    setEndDate(convertTimeZone(new Date(), timeZoneCode));
  }, [timeZoneCode]);

  // Helper function to handle date conversion before passing to API
  const getFormattedParams = () => ({
    playerType,
    startDate: convertToUtc(startDate),
    endDate: convertToUtc(endDate),
    timezone: timeZoneCode
  });

  const { data: dashboardData, isFetching: dashboardReportLoading, refetch: dashboardReportRefetch, isRefetching: isDashboardReportRefetching } = useQuery({
    queryKey: ['dashboardReport', timeZoneCode],
    queryFn: () => getReports({ ...getFormattedParams(), reportType: 'dashboardData' }),
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
  });

  const { data: reportData, isFetching: reportLoading, refetch: reportRefetch, isRefetching: isReportRefetching } = useQuery({
    queryKey: ['betReport', timeZoneCode],
    queryFn: () => getReports({ ...getFormattedParams(), reportType: 'loginData' }),
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
  });

  const { data: customerData, isFetching: customerLoading, refetch: customerRefetch, isRefetching: isCustomerRefetching } = useQuery({
    queryKey: ['customerReport', timeZoneCode],
    queryFn: () => getReports({ ...getFormattedParams(), reportType: 'customerData' }),
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
  });

  const { data: economyData, isFetching: economyLoading, refetch: economyRefetch, isRefetching: isEconomyRefetching } = useQuery({
    queryKey: ['economyReport', timeZoneCode],
    queryFn: () => getReports({ ...getFormattedParams(), reportType: 'economyData' }),
    enabled: economicDataAccordionOpen,
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
  });

  const { data: transactionData, isFetching: transactionLoading, refetch: transactionRefetch, isRefetching: isTransactionRefetching } = useQuery({
    queryKey: ['transactionReport', timeZoneCode],
    queryFn: () => getReports({ ...getFormattedParams(), reportType: 'transactionData' }),
    enabled: transactionDataAccordianOpen,
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
  });

  return {
    reportData,
    customerData,
    economyData,
    transactionData,
    reportRefetch,
    customerRefetch,
    transactionRefetch,
    economyRefetch,
    dashboardReportRefetch,
    reportLoading,
    customerLoading,
    economyLoading,
    transactionLoading,
    dashboardReportLoading,
    isReportRefetching,
    setTimeStamp,
    timeStamp,
    playerType,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setPlayerType,
    t,
    timeZoneCode,
    dashboardData
  };
};

export default useDashboardDataListing;
