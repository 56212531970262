import * as Yup from 'yup'

export const createBonusSchema = (t) => 
Yup.object().shape({
  bonusName: Yup.string()
    .required(t('errors.bonusName')),
  
  description: Yup.string()
    .required(t('errors.description')),

    bonusAmountSc: Yup.number()
  .min(0, 'Bonus SC cannot be negative'),

  bonusAmountGc: Yup.number()
  .min(0, 'Bonus GC cannot be negative'),
})