import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const BannerViewer = ({ thumbnailUrl, altText = 'Image', width = 100, height = 100 }) => {
  const [isFits, setIsFits] = useState(false);
  const [gameImage, setGameImage] = useState('');

  const viewImg = (url) => {
    setIsFits(true);
    setGameImage(url);
  }

  return (
    <>
        <img 
          src={thumbnailUrl} 
          alt={altText} 
          width={width} 
          height={height} 
          className='img-thumbnail' 
          onClick={() => viewImg(thumbnailUrl)}
        />

      {isFits && (
        <Lightbox
          mainSrc={gameImage}
          enableZoom={false}
          onCloseRequest={() => setIsFits(false)}
        />
      )}
    </>
  );
}

export default BannerViewer;
