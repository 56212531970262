import styled from 'styled-components';

export const QRBlockContainer = styled.div`
  // width: 200px;
  .qr-code-form {
    padding-top: 20px
  }
  .twofa-field {
    width: 270px !important;
  }
`
