import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEN from './locales/en';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
        en: {
          ...translationsEN,
        },
      },
    lng: 'en', // language to use
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;