import { useEffect } from "react";
import { useUserStore } from "../../../store/store";
import { loginCountSocket } from "../../../utils/socket";

const useMainRoute =()=>{
    
    const setIsUserAffiliate = useUserStore((state) => state.setIsUserAffiliate);
    const setloginCountSocketConnection = useUserStore((state) => state.setloginCountSocketConnection)
    const setlivePlayersCountConnection = useUserStore((state) => state.setlivePlayersCountConnection)

    const userDetails = useUserStore((state) => state)
    const affiliateUrls = [
      '/affiliate/signin',
      '/affiliate-admin/dashboard',
      '/affiliate-admin/profile',
      '/affiliate-admin/players',
      '/affiliate-admin/player-details/:userId',
      '/affiliates/set-Password',
      '/affiliate/transitions',
      '/affiliate/casinoTransition',
      '/affiliate/commission'
    ];
  
    const currentPath = window.location.pathname;

    const isAffiliateLogin = affiliateUrls.some(url => currentPath.includes(url));
    
    useEffect(()=>{
      if (isAffiliateLogin) {
        setIsUserAffiliate(true)
      }else{
        setIsUserAffiliate(false)
      }
    },[isAffiliateLogin])
    
    useEffect(() => {
      if (!userDetails.userDetails) return
      loginCountSocket.connect()
      setloginCountSocketConnection(true)
      setlivePlayersCountConnection(true)
      return () => {
        loginCountSocket.disconnect()
      }
    }, [!!userDetails.userDetails])

    return{
        isAffiliateLogin
    }
}

export default useMainRoute