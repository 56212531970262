import React, { useEffect } from "react";
import Trigger from "../OverlayTrigger";
import { faEdit, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card } from "@themesberg/react-bootstrap";
const LimitLabels = ({
  setLimitModal,
  setLimit,
  t,
  isHidden,
  label,
  value,
  minimum,
  limitType,
  selfExclusion,
}) => {


  return (
    <div key={value} className="col-12 col-lg-4">
      <div className="d-flex justify-content-between w-100" key={label}>
        <h6>{label}</h6>
        <div>
          <span>{value || t("playerLimit.notSet")}</span>
          <Trigger message={t("playerLimit.setLimit")} id={label + "_set"} />
          <Button
            id={label + "_set"}
            variant="warning"
            size="sm"
            onClick={() => {
              setLimit({ label, value, minimum, limitType, selfExclusion });
              setLimitModal(true);
            }}
            hidden={isHidden({ module: { key: "Users", value: "R" } })}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LimitLabels);
