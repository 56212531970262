import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { Col, Row, Form as BForm, Button, Spinner } from "@themesberg/react-bootstrap";
import {
  formatDateMDY,
  formatDateYMD,
  getDateTime,
  getDateTimeByYMD,
} from "../../../utils/dateFormatter.js";
import Datetime from "react-datetime";
import { serialize } from "object-to-formdata";
import { toast } from "../../../components/Toast/index.jsx";
import { errorHandler, useUpdateRaffleMutation } from "../../../reactQuery/hooks/customMutationHook/index.js";
import Trigger from "../../../components/OverlayTrigger/index.jsx";
import { AdminRoutes } from "../../../routes.js";
import { getRaffleDetail } from "../../../utils/apiCalls.js";
import { useQuery } from "@tanstack/react-query";
import { editRaffleSchema } from "../schemas.js";
import moment from 'moment'

const EditRaffle = ({ data }) => {
  const navigate = useNavigate();
  const { raffleId } = useParams();
  const yesterday = new Date();

  const { data: raffleDetail, isLoading: loading } = useQuery({
    queryFn: () => {
      return getRaffleDetail(raffleId);
    },
    select: (res) => res?.data?.getRaffleDetail,
    refetchOnWindowFocus: false,
  });
  // console.log(raffleDetail, 'raffleDetail')
  const { mutate: updateRaffle, isLoading: createLoading } =
    useUpdateRaffleMutation({
      onSuccess: (res) => {
        toast("Raffle Updated Successfully", "success");
        navigate(AdminRoutes.Raffle);
      },
      onError: (error) => {
        toast(error.response.data.errors[0].description, "error");
        errorHandler(error);
      },
    });

  const handleEditRaffleSubmit = (formValues) => {
    const body = {
      ...formValues,
      raffleId: raffleId,
      title: formValues.title,
      subHeading: formValues.subHeading,
      description: formValues.description,
      wagerBaseAmtType: formValues.wagerBaseAmtType,
      wagerBaseAmt: formValues.wagerBaseAmt,
      startDate: formatDateYMD(formValues.startDate),
      endDate: formatDateYMD(formValues.endDate),
      prizeAmountGc: formValues.prizeAmountGc,
      prizeAmountSc: formValues.prizeAmountSc,
      isActive: formValues.isActive,
      bannerImg: formValues.bannerImg,
      termsAndConditions: formValues.termsAndConditions,
    };
    updateRaffle(serialize(body));
  };
  return (
    <div>
      <Row>
        <Col sm={8}>
          <h3>Edit Giveaways</h3>
        </Col>
      </Row>

      {raffleDetail ? (
        <Formik
          initialValues={{
            title: raffleDetail?.title,
            subHeading: raffleDetail?.subHeading,
            description: raffleDetail?.description,
            wagerBaseAmtType: raffleDetail?.wagerBaseAmtType,
            wagerBaseAmt: raffleDetail?.wagerBaseAmt,
            startDate: raffleDetail?.startDate ? moment(raffleDetail.startDate).toDate() : new Date(),
            endDate: raffleDetail?.endDate ? moment(raffleDetail.endDate).toDate() : new Date(),
            prizeAmountGc: raffleDetail?.prizeAmountGc,
            prizeAmountSc: raffleDetail?.prizeAmountSc,
            isActive: raffleDetail?.isActive,
            termsAndConditions: raffleDetail?.termsAndConditions,
          }}
          validationSchema={editRaffleSchema}
          enableReinitialize
          onSubmit={handleEditRaffleSubmit}
        >  
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <Row>
                <Col>
                  <BForm.Label>Title</BForm.Label>

                  <BForm.Control
                    type="text"
                    name="title"
                    min="0"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component="div"
                    name="title"
                    className="text-danger"
                  />
                </Col>
                <Col>
                  <BForm.Label>Sub Heading</BForm.Label>

                  <BForm.Control
                    type="text"
                    name="subHeading"
                    min="0"
                    value={values.subHeading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component="div"
                    name="subHeading"
                    className="text-danger"
                  />
                </Col>
                <Col>
                  <BForm.Label>Description</BForm.Label>

                  <BForm.Control
                    type="text"
                    name="description"
                    min="0"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component="div"
                    name="description"
                    className="text-danger"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <BForm.Label >
                    Wagerbase Amount Type
                    <span className='text-danger'> *</span>
                  </BForm.Label>
                  <BForm.Select
                    type='text'
                    name={'wagerBaseAmtType'}
                    value={values.wagerBaseAmtType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >

                    {
                      <>
                        <option key={'SC'} value={'SC'}>
                          SC
                        </option>
                        <option key={'GC'} value={'GC'}>
                          GC
                        </option>
                      </>

                    }
                  </BForm.Select>
                </Col>
                <Col>
                  <BForm.Label>Wager Base Amount</BForm.Label>

                  <BForm.Control
                    type="number"
                    name="wagerBaseAmt"
                    min="0"
                    value={values.wagerBaseAmt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component="div"
                    name="wagerBaseAmt"
                    className="text-danger"
                  />
                </Col>
                <Col>
                  <BForm.Label>Prize Amount GC</BForm.Label>

                  <BForm.Control
                    type="number"
                    name="prizeAmountGc"
                    min="0"
                    value={values.prizeAmountGc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component="div"
                    name="prizeAmountGc"
                    className="text-danger"
                  />
                </Col>
                <Col>
                  <BForm.Label>Prize Amount SC</BForm.Label>

                  <BForm.Control
                    type="number"
                    name="prizeAmountSc"
                    min="0"
                    value={values.prizeAmountSc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component="div"
                    name="prizeAmountSc"
                    className="text-danger"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <BForm.Label>
                    StartDate
                    <span className="text-danger"> *</span>
                  </BForm.Label>
                  <Datetime
                    inputProps={{
                      placeholder: "MM-DD-YYYY HH:MM",
                      disabled: false,
                    }}
                    dateFormat="MM/DD/YYYY"
                    onChange={(e) => {
                      setFieldValue("startDate", e);
                    }}
                    value={values.startDate}
                    isValidDate={(e) => {
                      return (
                        e._d > yesterday ||
                        getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())
                      );
                    }}
                    timeFormat={false}
                  />
                  <ErrorMessage
                    component="div"
                    name="startDate"
                    className="text-danger"
                  />
                </Col>
                <Col>
                  <BForm.Label>
                    End Date
                    <span className="text-danger"> *</span>
                  </BForm.Label>
                  <Datetime
                    inputProps={{
                      placeholder: "MM-DD-YYYY HH:MM",
                      disabled: false,
                    }}
                    dateFormat="MM/DD/YYYY"
                    onChange={(e) => {
                      setFieldValue("endDate", e);
                    }}
                    value={values.endDate}
                    isValidDate={(e) => {
                      return (
                        e._d > yesterday ||
                        getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())
                      );
                    }}
                    timeFormat={false}
                  />
                  <ErrorMessage
                    component="div"
                    name="endDate"
                    className="text-danger"
                  />
                </Col>
                <Col>
                  <BForm.Label>Active</BForm.Label>

                  <BForm.Check
                    type="switch"
                    name="isActive"
                    checked={values.isActive}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component="div"
                    name="isActive"
                    className="text-danger"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <BForm.Label>Terms & Condition</BForm.Label>

                  <BForm.Control
                    type="text"
                    name="termsAndConditions"
                    min="0"
                    value={values.termsAndConditions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component="div"
                    name="termsAndConditions"
                    className="text-danger"
                  />
                </Col>
                <Col className="d-grid">
                  <BForm.Label>Banner Image</BForm.Label>

                  <BForm.Text>
                    <Trigger message="mes" id={"mes"} />
                    <input
                      id={"mes"}
                      title=" "
                      name="bannerImg"
                      type="file"
                      onChange={(event) => {
                        setFieldValue(
                          "bannerImg",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    {values?.bannerImg && (
                      <img
                        alt="not found"
                        width="100px"
                        src={URL.createObjectURL(values?.bannerImg)}
                      />
                    )}
                    {!values?.bannerImg && raffleDetail?.imageUrl && (
                      <img
                        alt="not found"
                        width="60px"
                        src={raffleDetail?.imageUrl}
                      />
                    )}
                  </BForm.Text>

                  <ErrorMessage
                    component="div"
                    name="bannerImg"
                    className="text-danger"
                  />
                </Col>
              </Row>

              <div className="mt-4 d-flex justify-content-between align-items-center">
                <Button
                  variant="warning"
                  onClick={() => navigate(AdminRoutes.Raffle)}
                >
                  Cancel
                </Button>

                <Button
                  variant="success"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="ml-2"
                  disabled={createLoading}
                >
                  Submit
                  {createLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EditRaffle;
