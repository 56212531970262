import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllTierListParams,getAllTierUserListParams} from '../../../utils/apiCalls'
import { useState } from 'react'

const useTierDetails = () => {
  const { tierId } = useParams()
  const navigate = useNavigate()

  const { data: tournamentData, isLoading: loading } = useQuery({
    queryKey: ['tierId', tierId ],
    queryFn: ({ queryKey }) => {
      const params = {};
      return getAllTierListParams({tierId})
    },
    select: (res) => res?.data,
    refetchOnWindowFocus: false
  })


  return {
    tournamentData,
    loading,
    navigate
  }
}

export default useTierDetails