export const tableHeaders = (isAllUser) => [
    { labelKey: 'transactions.headers.id', value: 'transactionId' },
   // { labelKey: 'transactions.headers.paymentId', value: 'paymentId' },
    { labelKey: isAllUser ? 'transactions.headers.email' : 'transactions.headers.actioneeName', value: 'actioneeName' },
    // { labelKey: 'transactions.headers.actionType', value: 'scCoin' },
    { labelKey: 'transactions.headers.amount', value: 'isActive' },
    { labelKey: 'transactions.headers.gcCoin', value: 'gcCoin' },
    { labelKey: 'transactions.headers.scCoin', value: 'scCoin' },
    { labelKey: 'transactions.headers.transactionType', value: 'transactionType' },
    { labelKey: 'transactions.headers.status', value: 'PackageUsers'},
    { labelKey: 'transactions.headers.createdAt', value: 'action' },
    { labelKey: 'transactions.headers.action', value: 'action' },

]

export const TRANSACTION_STATUS = {
    0: 'Pending',
    1: 'Success',
    2: 'Cancelled',
    3: 'Failed',
    4: 'Rollback',
    5: 'Approved',
    6: 'Declined',
    9: 'Void',
    10: 'Refund'
  }