import React from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Row, Table } from "@themesberg/react-bootstrap";
import {
  formatDateMDY,
} from "../../../utils/dateFormatter.js";

import { getPromotionBonusDetail } from "../../../utils/apiCalls.js";
import { useQuery } from "@tanstack/react-query";
import { viewPromocodeHeaders } from "../constant.js";

const ViewPromotionBonus = ({ data }) => {
  const { promocodeId } = useParams();
  const { data: promoDetail, isLoading: loading } = useQuery({
        queryFn: () => {
          return getPromotionBonusDetail(promocodeId);
        },
        select: (res) => res?.data?.promoCodeDetails,
        refetchOnWindowFocus: false,
      });

  return (
    <div>
      <Row>
        <Col sm={8}>
          <h3>View Affiliate Promo Codes </h3>
        </Col>
      </Row>
      <Row>
                <Col xs={12} md={6} lg={3} >
                    <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                        <h6 className='mb-0 me-2'>Promocode</h6>
                        <span >{promoDetail?.promoDetail?.promocode}</span>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={3} >
                    <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                        <h6 className='mb-0 me-2'>Affiliate Id</h6>
                        <span >{promoDetail?.promoDetail?.affiliateId}</span>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={3} >
                    <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                        <h6 className='mb-0 me-2'>Valid Till</h6>
                        <span >{promoDetail?.promoDetail?.validTill ? formatDateMDY(promoDetail?.promoDetail?.validTill) : 'N/A'}</span>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={3} >
                    <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                        <h6 className='mb-0 me-2'>Status</h6>
                        <span >{promoDetail?.promoDetail?.isActive == true ? 'True' : 'False'}</span>
                    </div>
                </Col>

            </Row>
      <Table
        bordered
        striped
        responsive
        hover
        size="sm"
        className="text-center mt-4"
      >
        <thead className="thead-dark">
          <tr>
            {viewPromocodeHeaders.map((h, idx) => (
              <th
                key={idx}
                style={{
                  cursor: "pointer",
                }}
              >
                {h.labelKey}{" "}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {promoDetail?.userBonusDetail?.count > 0 &&
            promoDetail?.userBonusDetail?.rows?.map(
                ({
                    userId,
                    User,
                    gcAmount,
                    scAmount,
                    claimedAt
                }) => {
                return (
                  <tr key={userId}>
                    <td>{userId}</td>
                    <td>{User?.username}</td>
                    <td><Link to={`/admin/player-details/${userId}`}>{User?.email}</Link></td>
                    <td>{scAmount}</td>
                    <td>{gcAmount}</td>
                    <td>{formatDateMDY(claimedAt)}</td>
                  </tr>
                );
              }
            )}

          {promoDetail?.userBonusDetail?.count === 0 && (
            <tr>
              <td colSpan={7} className="text-danger text-center">
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
     
    </div>
  );
};

export default ViewPromotionBonus;
