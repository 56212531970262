export const GET_USER_DOCUMENT = 'GET_USER_DOCUMENT'
export const GET_PACKAGES_LISTING = 'GET_PACKAGES_LISTING'
export const GET_PACKAGES_TYPES_LISTING = 'GET_PACKAGES_TYPES_LISTING'
export const GET_GAMES_IDS_LISTING = 'GET_GAMES_IDS_LISTING'
export const GET_STATE_LISTING = 'GET_STATE_LISTING'
export const GET_CITY_LISTING = 'GET_PACKAGES_TYPES_LISTING'
export const GET_PLAYER_RESPONSIBLE = 'GET_PLAYER_RESPONSIBLE'
export const GET_PLAYER_BANK_DETAILS = 'GET_PLAYER_BANK_DETAILS'
export const GET_PLAYER_CASINO_DETAILS = 'GET_PLAYER_CASINO_DETAILS'
export const GET_PLAYER_ACTIVITY_DETAILS = 'GET_PLAYER_ACTIVITY_DETAILS'
export const GET_2FA_ACTIVITY_DETAILS = 'GET_2FA_ACTIVITY_DETAILS'
