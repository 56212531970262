import React, { useState } from "react";
import { Row, Col, Button, Tabs, Tab, Nav, Accordion } from "@themesberg/react-bootstrap";
import EditInfo from "./components/EditInfo";

import { PlayersTabInfo } from "./constants";
import ResponsibleGaming from "../../components/ResponsibleGaming";
import AuditTable from "./components/AuditTable";
import RSGData from "./components/RSGData";
import LogsTable from "./components/LogsTable";
import CommsTable from "./components/CommsTable";
import ActivityTable from "./components/Activity/ActivityTable";
import BankDetails from "./components/BankDetails";
import { PlayerTabContainer } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const ActionDetail = (props) => {
  const {
    basicInfo,
    getUserDetails,
    userData,
    userLimits,
    handelRefetchActivity,
    currentSelectedTab,
    accordionOpen ,setAccordionOpen
  } = props;
  const [selectedInnerButton, setSelectedInnerButton] = useState({});
  const [openEditInfoModal, setOpenEditInfoModal] = useState(false);

  const setSelectedInnerToggler = (dataValue) => {
    switch (dataValue.innerItem) {
      case "isBan":
      case "is2FaEnabled":
      case "isRestrict":
      case "phoneVerified":
      case "isRedemptionSubscribed":
      case "isSubscribed":
      case "isInternalUser":
      case "addDeductCoinsChild":
      case "addDeduct1ScCoinChild":
      case "addDeduct2ScCoinsChild":
      case "forceLogoutChild":
      case "passwordChild":
      case "removePwLock":
      case "isUserVerified":
      case "socialSecurityChild":
        setOpenEditInfoModal(true);
        break;
      default:
        break;
    }
    setSelectedInnerButton(dataValue);
  };
  return (
    <React.Fragment>
    <Row className='mt-4' onClick={() => setAccordionOpen(!accordionOpen)} style={{ cursor: 'pointer' }}>
        <h5 className='accordian-heading'>
            <span>Player Action</span>
            <span>{accordionOpen ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />} </span>
        </h5>
    </Row>

    <Accordion activeKey={accordionOpen ? '0' : ''}>
        <Accordion.Item eventKey="0">
            <Accordion.Body>
    <PlayerTabContainer>
      <Row className='mt-5 player-tab-wrap'>
        <Col className='text-center p-0'>
          {PlayersTabInfo[currentSelectedTab]?.childLabel?.map(
            (innerItem, index) => {
              if (innerItem.key === "is2FaEnabled" && !userData?.is2FAEnable) {
                return null;
              }
              return (
                <Button
                  disabled={
                    innerItem.key === "removePwLock" &&
                    basicInfo.passwordAttempt <= 4
                  }
                  variant='warning'
                  className='me-2 my-2 edit-inner-tabwrap'
                  key={index}
                  onClick={() =>
                    setSelectedInnerToggler({
                      currentSelectedTab,
                      innerItem: innerItem.key,
                      type: innerItem?.type,
                    })
                  }
                >
                  {innerItem?.label}
                </Button>
              );
            }
          )}
          {selectedInnerButton?.innerItem === "limitsChild" &&
            currentSelectedTab === "editParent" && (
              <Col>
                <ResponsibleGaming
                  userLimits={userLimits}
                  user={userData}
                  currencyCode={userData?.currencyCode}
                  getUserDetails={getUserDetails}
                />
              </Col>
            )}
          {selectedInnerButton?.innerItem === "bankDetailsChild" &&
            selectedInnerButton?.currentSelectedTab === "editParent" && (
              <Col>
                <BankDetails user={userData} />
              </Col>
            )}
        </Col>
      </Row>
      {selectedInnerButton?.currentSelectedTab === "editParent" && (
        <EditInfo
          basicInfo={basicInfo}
          selectedInnerButton={selectedInnerButton}
          openEditInfoModal={openEditInfoModal}
          setOpenEditInfoModal={setOpenEditInfoModal}
          getUserDetails={getUserDetails}
          handelRefetchActivity={handelRefetchActivity}
        />
      )}
     
    </PlayerTabContainer>
    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </React.Fragment>
  );
};

export default ActionDetail;
