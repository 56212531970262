import React from 'react'

const AffiliateCasinoTransitions = () => {
  return (
    <div>
      Casino Transitions
    </div>
  )
}

export default AffiliateCasinoTransitions
