import React, { useEffect, useState } from 'react'
import {
    Button,
    Row,
    Col,
    Table,
    ButtonGroup,
    Form as BForm
} from '@themesberg/react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import { getPayoutUser, getPayoutUserSearch, getRaffleDetail, getRafflePayout } from '../../../utils/apiCalls';
import { useQuery } from '@tanstack/react-query';
import { ErrorMessage, Form, Formik } from 'formik';
import Trigger from '../../../components/OverlayTrigger';
import Datetime from 'react-datetime';
import { formatDateMDY } from '../../../utils/dateFormatter';
import { errorHandler, usePayoutUserSearchMutation, useRafflePayoutMutation } from '../../../reactQuery/hooks/customMutationHook';
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { AdminRoutes } from '../../../routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { serialize } from 'object-to-formdata';
import toast from 'react-hot-toast';
const RafflePayout = () => {
    const navigate = useNavigate();
    const { raffleId } = useParams();
    const [searchId, setSearchId] = useState(0)

    const { data: rafflePayoutDetail, isLoading: loading } = useQuery({
        queryFn: () => {
            return getRafflePayout(raffleId)
        },
        select: (res) => res?.data?.rafflePayout,
        refetchOnWindowFocus: false
    })
    const { data: raffleDetail, isLoading: detailloading } = useQuery({
        queryKey: ['RaffleDetail'],
        queryFn: () => {
            return getRaffleDetail(raffleId)
        },
        select: (res) => res?.data?.getRaffleDetail,
        refetchOnWindowFocus: false
    })

    const handleSearch = (id) => {
        setSearchId(id)
    }
    const { data: payoutSearch, isLoading: payoutSearchLoading } = useQuery({
        queryKey: ['payoutsearch', raffleId, searchId],
        queryFn: ({ queryKey }) => {
            const params = {};
            if (queryKey[1] && queryKey[2] > 0) {
                params.raffleId = queryKey[1]
                params.entryId = queryKey[2]
                return getPayoutUserSearch(params)
            }
            else {
                return
            }

        },
        select: (res) => res?.data?.rafflePayout,
        refetchOnWindowFocus: false
    })
    const handlePayout = (raffleId, entryId) => {
        const body = {
            raffleId: +raffleId,
            entryId: +entryId,
        };
        rafflePayout(body)
    }
    const { mutate: rafflePayout, isLoading: createLoading } = useRafflePayoutMutation({
        onSuccess: (res) => {
            toast('Awarded user payout successfully', 'success')
            navigate(AdminRoutes.Raffle)
        }, onError: (error) => {
            toast(error.response.data.errors[0].description, 'error')
            errorHandler(error)
        }
    })
    return (
        <>
            <Row className='mb-2'>
                <Col>
                    <h3>Giveaways Payout</h3>
                </Col>
            </Row>
            {rafflePayoutDetail ?
                <Formik
                    initialValues={{
                        title: rafflePayoutDetail?.title,
                        endDate: rafflePayoutDetail?.endDate,
                        description: rafflePayoutDetail?.description,
                        totalNoOfEntryTickets: rafflePayoutDetail?.entryRequiredDetails?.totalNoOfEntryTickets,
                        firstTicketId: rafflePayoutDetail?.entryRequiredDetails?.firstTicketId,
                        lastTicketId: rafflePayoutDetail?.entryRequiredDetails?.lastTicketId,

                        awardedTicketId: '',
                        prizeAmountSc: rafflePayoutDetail?.prizeAmountSc,
                        isActive: rafflePayoutDetail?.isActive,
                    }}
                    enableReinitialize
                // onSubmit={handleEditRaffleSubmit}
                >
                    {({
                        values,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                    }) => (
                        <Form>
                            <Row>
                                <Col>
                                    <BForm.Label>
                                        Title

                                    </BForm.Label>

                                    <BForm.Control
                                        type='text'
                                        name='title'
                                        min='0'
                                        value={values.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={true}
                                    />

                                    <ErrorMessage
                                        component='div'
                                        name='title'
                                        className='text-danger'
                                    />
                                </Col>
                                <Col>
                                    <BForm.Label>
                                        End Date
                                    </BForm.Label>
                                    <Datetime
                                        inputProps={{
                                            disabled: true
                                        }}
                                        dateFormat='MM/DD/YYYY'
                                        onChange={(e) => {
                                            setFieldValue('endDate', formatDateMDY(e._d));
                                        }}
                                        value={values.endDate}
                                        isValidDate={(e) => {
                                            return (
                                                e._d > new Date() ||
                                                formatDateMDY(e._d) === formatDateMDY(new Date())
                                            );
                                        }}
                                        timeFormat={true}
                                    />
                                    <ErrorMessage
                                        component='div'
                                        name='endDate'
                                        className='text-danger'
                                    />
                                </Col>
                                <Col>
                                    <BForm.Label>
                                        Description

                                    </BForm.Label>

                                    <BForm.Control
                                        type='text'
                                        name='description'
                                        min='0'
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={true}
                                    />

                                    <ErrorMessage
                                        component='div'
                                        name='description'
                                        className='text-danger'
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <BForm.Label>
                                        Ticket Awarded

                                    </BForm.Label>

                                    <BForm.Control
                                        type='number'
                                        name='totalNoOfEntryTickets'
                                        min='0'
                                        value={values.totalNoOfEntryTickets}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={true}
                                    />

                                    <ErrorMessage
                                        component='div'
                                        name='totalNoOfEntryTickets'
                                        className='text-danger'
                                    />
                                </Col>
                                <Col>
                                    <BForm.Label>
                                        Ticket ID
                                    </BForm.Label>
                                    <Row>
                                        <Col>
                                            <BForm.Control
                                                type='number'
                                                name='firstTicketId'
                                                min='0'
                                                value={values.firstTicketId}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col>
                                            <BForm.Control
                                                type='number'
                                                name='lastTicketId'
                                                min='0'
                                                value={values.lastTicketId}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            <Row className='mt-3'>
                                <Col sm={8}>
                                    <h5>User Detail</h5>

                                    <Table bordered striped responsive hover size='sm' className='text-center mt-4' >
                                        <thead>
                                            <tr>
                                                <th>
                                                    User Id
                                                </th>
                                                <th>
                                                    Enteries
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                raffleDetail?.userEntry.length > 0 ?
                                                    raffleDetail?.userEntry.map((e, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {e?.userId}
                                                                </td>
                                                                <td>
                                                                    <tr>
                                                                        <th>Entry Id</th>
                                                                        <th>Awarded Date</th>
                                                                    </tr>
                                                                    {e.RafflesEntry.length > 0 ?
                                                                        e.RafflesEntry.map((i, idx) => {
                                                                            return (
                                                                                <tr key={idx}>
                                                                                    <td>{i.entryId}</td>
                                                                                    <td>{formatDateMDY(i.createdAt)}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        : <></>}
                                                                </td>

                                                            </tr>

                                                        )
                                                    })

                                                    : <>
                                                        <tr>
                                                            <td colSpan={3} className='text-danger text-center'>
                                                                No Data Found
                                                            </td>
                                                        </tr>
                                                    </>

                                            }

                                        </tbody>
                                    </Table>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BForm.Label>
                                        Awarded Ticket Id
                                    </BForm.Label>

                                    <BForm.Control
                                        type='text'
                                        name='awardedTicketId'
                                        min='0'
                                        value={values.awardedTicketId}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <ErrorMessage
                                        component='div'
                                        name='awardedTicketId'
                                        className='text-danger'
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        variant='success'
                                        onClick={() => {
                                            handleSearch(values.awardedTicketId);
                                        }}
                                        className='ml-2 mt-4'
                                    >
                                        Search
                                    </Button></Col>
                            </Row>
                            {payoutSearch ?
                                <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th>UserName</th>
                                            <th>Email</th>
                                            <th>Winner Ticket Id</th>
                                            <th>Raffle Id</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>{payoutSearch?.User?.username}</td>
                                            <td>{payoutSearch?.User?.email}</td>
                                            <td>{payoutSearch?.entryId}</td>
                                            <td>{payoutSearch?.raffleId}</td>
                                            <td>
                                                <Trigger message='Payout' id={raffleId + 'payout'} />
                                                <Button
                                                    id={raffleId + 'payout'}
                                                    className='m-1'
                                                    size='sm'
                                                    variant='warning'
                                                    onClick={() => handlePayout(payoutSearch?.raffleId, payoutSearch?.entryId)}
                                                >
                                                    <FontAwesomeIcon icon={faPlayCircle} />
                                                </Button></td>
                                        </tr>
                                    </tbody>
                                </Table>

                                : <></>}

                        </Form>
                    )}
                </Formik>
                : <></>
            }

        </>
    )
}

export default RafflePayout