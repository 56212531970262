import {
  Col,
  Row,
  Form,
  Button,
  Table,
  Badge,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { statusOptions } from "./constants";
import DateRangePicker from "../../components/DateRangePicker";
import { getDateDaysAgo, getDateThreeMonthsBefore, getDateTime, getStartDateMonthsAgo } from "../../utils/dateFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFileDownload,
  faRedoAlt,
  faTimes,
  faTimesSquare,
} from "@fortawesome/free-solid-svg-icons";
import Trigger from "../../components/OverlayTrigger";
import useWithdrawTransactions from "./hooks/useWithdrawTransactions";
import { InlineLoader } from "../../components/Preloader";
import PaginationComponent from "../../components/Pagination";
import { faEdit, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "../../components/Toast";
import { ApproveRedeemConfirmation, RedeemMoreDetail } from "../../components/ConfirmationModal";
import { convertTimeZone, convertToTimeZone, getFormattedTimeZoneOffset, onDownloadCsvClick } from "../../utils/helper";
import { getItem } from "../../utils/storageUtils";
import { timeZones } from "../Dashboard/constants";
import DatePicker from "react-datepicker";
import Datetime from 'react-datetime'
const WithdrawRequests = () => {
  const [type, setType] = useState('')
  const {
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedAction,
    startDate, setStartDate,endDate, setEndDate,
    transactionData,
    loading,
    search,
    setSearch,
    updateWithdrawData,
    approveModal,
    setApproveModal,
    redeemRequest,
    setRedeemRequest,
    updateLoading,
    handelFetchStatus,
    getCsvDownloadUrl,
    setRedeemMoreDetail,
    redeemMoreDetail,
    moreDetailData, setMoreDetailData,timezoneOffset
  } = useWithdrawTransactions();
 
  const handleApproveRequest = () => {
    updateWithdrawData({
      withdrawRequestId: redeemRequest?.withdrawRequestId.toString(),
      reason: "",
      userId: redeemRequest?.userId,
      status: type,
    });

  }
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [reasonData, setReasonData] = useState(null);
  
  const handleRedeem = () => {

  }

  const handleDownloadClick = async () => {
    try {
      setDownloadInProgress(true); 
      const url = getCsvDownloadUrl();
      await  onDownloadCsvClick(url, 'Redeem_Request')
    } catch (error) {
      console.error('Error downloading CSV:', error);
    } finally {
      setDownloadInProgress(false); 
    }
  };
  return (
    <>
      <Row className='mb-3'>
        <Col xs={12}>
          <h3>Redeem Requests</h3>
        </Col>
      </Row>
      <Row>
        <Col xs='12' sm='6' lg='3' className='mb-3'>
          <Form.Label
          >
            Search
          </Form.Label>
          <Form.Control
            type='search'
            value={search}
            placeholder='Search By Email'
            onChange={(event) => {
              setPage(1);
              setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ""));
            }}
          />
        </Col>
        <Col xs='12' sm='6' lg='3' className='mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Status
          </Form.Label>

          <Form.Select
            onChange={(e) => {
              setPage(1);
              setSelectedAction(e.target.value);
            }}
            value={selectedAction}
          >
            {statusOptions &&
              statusOptions?.map(({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
          </Form.Select>
        </Col>
        {/* <Col xs='12' sm='6' lg='3' className='mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Time Period
          </Form.Label>
          <DateRangePicker width='auto' state={state} setState={setState} timeZoneCode={timezoneOffset} startDate = {startDate} endDate={endDate}/>
        </Col> */}
         <Col
            className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
          >
            <Form.Label column="sm" className="mx-auto text-nowrap px-2">
              Start Date
            </Form.Label>
            <Datetime
              value={startDate}
              onChange={(date) => setStartDate(date)}
              timeFormat={false}
            />
          </Col>
          <Col
            className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
          >
            <Form.Label column="sm" className="mx-auto text-nowrap px-2">
              End Date
            </Form.Label>
            <Datetime
              value={endDate}
              onChange={(date) => setEndDate(date)}
              timeFormat={false}
            />
          </Col>
        <Col xs='12' sm='6' lg='1' className='d-flex align-items-end mt-2 mt-sm-0 mb-0 mb-lg-3'>
          <Trigger message='Reset Filters' id={"redo"} />
          <Button
            id={"redo"}
            variant='success'
            onClick={() => {
              setSearch("");
              setSelectedAction("pending");
              setLimit(15);
              setPage(1);
              setStartDate(getStartDateMonthsAgo(3)),
              setEndDate(new Date())
            }}
          >
            <FontAwesomeIcon icon={faRedoAlt} />
          </Button>

          <Trigger message='Download as CSV' id={'csv'} />
            <Button
            id={'csv'}
              variant='success' style={{ marginLeft: '10px' }}
              onClick={handleDownloadClick}
            >
              {downloadInProgress ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : (
              <FontAwesomeIcon icon={faFileDownload} />
              )}
            </Button>
        </Col>
      </Row>
      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            <th>Id</th>
            <th>Transaction Id</th>
            <th>Email</th>
            <th>Amount (SC)</th>
            <th>User Enable</th>
            <th>Updated At</th>
            <th>Action</th>
            <th>Detail</th>
          </tr>
        </thead>
        <tbody>
          {transactionData &&
            transactionData?.rows?.map(
              ({
                transactionId,
                email,
                name,
                withdrawRequestId,
                actionableEmail,
                amount,
                userId,
                status,
                updatedAt,
                paymentProvider,
                isUserEnabled,
                isMaxRedeemedFloridaUser,
                totalPurchaseAmount,
                totalGGR,
                recentApprovedRedeemDate,
                totalRedeemedAmount,
                moreDetails
              }) => {
                return (
                  <tr key={transactionId} style={isMaxRedeemedFloridaUser === true ? {background:"#f6869b"} : {}}>
                    <td>{withdrawRequestId}</td>
                    <td>{transactionId}</td>
                    <td>
                      <Link to={`/admin/player-details/${userId}`}>
                        {email}
                      </Link>
                    </td>
                    <td>{amount?.toFixed(2)}</td>
                   <td>{isUserEnabled ===  true ? 'True' : 'False'}</td>
                  
                    {/* <td>{status === 0 ? 'Pending' : status === 1 ? 'Success' : 'Failed'}</td> */}
                    <td>{getDateTime(convertToTimeZone(updatedAt, timezoneOffset))}</td>
                    <td>
                      {status === 0 ? (
                        <>
                          <Trigger
                            message='Approve'
                            id={transactionId + "edit"}
                          />
                          <Button
                            disabled={status !== 0 || updateLoading}
                            id={transactionId + "edit"}
                            className='m-1'
                            size='sm'
                            variant='success'
                            onClick={() => {
                              setType('approved')
                              setApproveModal(true)
                              setRedeemRequest({ userId, withdrawRequestId, paymentProvider })
                            }}
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </Button>
                        </>
                      ) : status === 1 ? (
                        <span className='success'>Approved</span>
                      ) : status === 2 ? (
                        <span className='danger'>Cancelled</span>
                      ) : status === 7 ? (
                        <button className='btn btn-success btn-sm' onClick={() => handelFetchStatus(withdrawRequestId)}>Check Status</button>
                      ) : status === 6 ? (
                        <span className='danger'>Declined</span>
                      ) : (
                        <span className='danger'>Failed</span>
                      )}
                      {status === 0 && (
                        <>
                          <Trigger
                            message='Cancel'
                            id={transactionId + "Cancel"}
                          />
                          <Button
                            disabled={status !== 0 || updateLoading}
                            id={transactionId + "Cancel"}
                            className='m-1'
                            size='sm'
                            variant='danger'
                            onClick={() => {
                              setType('rejected')
                              setApproveModal(true)
                              setRedeemRequest({ userId, withdrawRequestId, paymentProvider })
                            }}
                          >
                            <FontAwesomeIcon icon={faTimesSquare} />
                          </Button>
                        </>
                      )}
                    </td>
                    <td><Button style={{padding:'3px 8px'}} onClick={() => {setRedeemMoreDetail(true)
                    setReasonData(moreDetails)
                    setMoreDetailData({totalPurchaseAmount,totalGGR,recentApprovedRedeemDate, name,actionableEmail,totalRedeemedAmount})
                   }}>More Detail</Button></td>
                  </tr>
                );
              }
            )}

          {transactionData?.count === 0 && (
            <tr>
              <td colSpan={9} className='text-danger text-center'>
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {loading && <InlineLoader />}
      {transactionData?.count !== 0 && (
        <PaginationComponent
          page={transactionData?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
      {approveModal &&
        <ApproveRedeemConfirmation
          show={approveModal}
          setShow={setApproveModal}
          handleYes={handleApproveRequest}
          redeemRequest={redeemRequest}
          type={type}
        />}
      {redeemMoreDetail && 
        <RedeemMoreDetail
          show={redeemMoreDetail}
          setShow={setRedeemMoreDetail}
          handleYes={handleRedeem}
          moreDetailData={moreDetailData}
          reasonData={reasonData}
        />}
    </>
  );
};

export default WithdrawRequests;
