import { useEffect, useState } from 'react'
import { formatDateYMD, getDateDaysAgo, getStartDateMonthsAgo } from '../../../utils/dateFormatter'
import { getItem, getLoginToken } from '../../../utils/storageUtils'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getAllTransactions, getUserWithdrawRequests, getWithdrawRequests } from '../../../utils/apiCalls'
import { useDebounce } from 'use-debounce'
import { toast } from '../../../components/Toast'
import { errorHandler, useFetchWithdrawRequestStatusMutation, useUpdateWithdrawRequestMutation } from '../../../reactQuery/hooks/customMutationHook'
import useTimeConversion from '../../../utils/TimeConversion'
import { useUserStore } from '../../../store/store'
import { timeZones } from '../../Dashboard/constants'
import { convertTimeZone, convertToUtc, getFormattedTimeZoneOffset } from '../../../utils/helper'

const useWithdrawTransactions = () => {
  const {permissions} = useUserStore((state) => state);
  const [selectedAction, setSelectedAction] = useState('pending')
  const [fetchStatusId, setFetchStatusId] = useState('')
  const [approveModal, setApproveModal] = useState(false)
  const [redeemMoreDetail, setRedeemMoreDetail] = useState(false)
  const [redeemRequest, setRedeemRequest] = useState({})
  const [moreDetailData, setMoreDetailData] = useState({})
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 500)
  const queryClient = useQueryClient()
  const timezone = getItem("timezone")
  // const [state, setState] = useState([
  //   {
  //     startDate: getStartDateMonthsAgo(3),
  //     endDate: new Date(),
  //     key: 'selection'
  //   }
  // ])
  const timeZone = getItem("timezone");
  const timezoneOffset =  timeZone != null ? timeZones.find(x=> x.code === timeZone).value : getFormattedTimeZoneOffset()
  const [timeZoneCode, setTimeZoneCode] = useState(timeZones.find(x=> x.value === timezoneOffset)?.code);
  const [startDate, setStartDate] = useState(convertTimeZone(getStartDateMonthsAgo(3), timeZoneCode));
  const [endDate, setEndDate] = useState(convertTimeZone(new Date(), timeZoneCode));

  useEffect(()=>{
    setTimeZoneCode(timeZones.find(x=> x.value === timezoneOffset)?.code)
  },[timezoneOffset])

  useEffect(() => {
    setStartDate(convertTimeZone(getStartDateMonthsAgo(3), timeZoneCode));
    setEndDate(convertTimeZone(new Date(), timeZoneCode));
  }, [timeZoneCode]);

  const { data: transactionData, isLoading: loading } = useQuery({
    queryKey: ['withdrawList', limit, page, selectedAction,convertToUtc(startDate), convertToUtc(endDate), debouncedSearch,timezone],
    queryFn: ({ queryKey }) => {
      const params = { pageNo: queryKey[2], limit: queryKey[1] };
      if (queryKey[3]) params.status = queryKey[3]
      if (queryKey[4]) params.startDate = queryKey[4]
      if (queryKey[5]) params.endDate = queryKey[5]
      if (queryKey[6]) params.email = queryKey[6]
      if (queryKey[7]) params.timezone = queryKey[7]
      if(permissions?.Users){
        return getUserWithdrawRequests(params)
      }
      else{
        return getWithdrawRequests(params)
      }
    },
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.requestDetails
  })

  const totalPages = Math.ceil(transactionData?.count / limit)
  
  const getCsvDownloadUrl = () =>
    `${process.env.REACT_APP_API_URL}/api/v1/payment/redeem-requests?csvDownload=true&limit=${limit}&pageNo=${page}&email=${debouncedSearch}&status=${selectedAction}&startDate=${convertToUtc(startDate)}&endDate=${convertToUtc(endDate)}&timezone=${timezone}`
  
  const { mutate: updateWithdrawalRequest, isLoading: updateLoading } = useUpdateWithdrawRequestMutation({
    onSuccess: (data) => {
      if (data.data.success) {
        toast('Withdraw request updated successfully', 'success')
        queryClient.invalidateQueries({ queryKey: ['withdrawList'] })
      } else {
        toast(data.data.message, 'error')
      }
    }, onError: (error) => {
      if (error?.response?.data?.errors.length > 0) {
        const { errors } = error.response.data;
        errors.map((error) => {
          if (error?.errorCode === 500) {
            toast('Something Went Wrong', 'error')
          }
          if (error?.description) {
            toast(error?.description, 'error')
          }
        })
      }
    }
  })

  const updateWithdrawData = (data) => {
    updateWithdrawalRequest(data)
  }
  const onFetchStatusSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['withdrawList'] })
    toast("Updated Successfully", 'success')
  }

  const onFetchStatusError = (err) => {
    if (err?.response?.data?.errors.length > 0) {
      const { errors } = err.response.data
      errors.forEach((error) => {
        if (error?.description) {
          if (error.errorCode === 3007) {
            console.log(error)
          } else toast(error?.description, "error")
        }
      })
    }
  }
  const fetchStatusMutation = useFetchWithdrawRequestStatusMutation({
    onSuccess: onFetchStatusSuccess,
    onError: onFetchStatusError
  })
  const handelFetchStatus = (id) => {
    setFetchStatusId(id)
    fetchStatusMutation.mutate({withdrawRequestId: id});
  }
  return {
    updateWithdrawData,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedAction,
    startDate, setStartDate,endDate, setEndDate,
    transactionData,
    loading,
    search,
    setSearch,
    approveModal,
    setApproveModal,
    redeemRequest,
    setRedeemRequest,
    updateLoading,
    handelFetchStatus,
    getCsvDownloadUrl,
    setRedeemMoreDetail,
    redeemMoreDetail,timezoneOffset,
    moreDetailData, setMoreDetailData
  }
}

export default useWithdrawTransactions
