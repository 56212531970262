import React from 'react'

const AffiliatePlayerDetails = () => {
  return (
    <div>
      PlayerDetails
    </div>
  )
}

export default AffiliatePlayerDetails
