export const tableHeaders = [
    { labelKey: 'Id', value: 'raffleId' },
    { labelKey: 'Title', value: 'title' },
    // { labelKey: 'Description', value: 'description' },
    // { labelKey: 'SubHeading', value: 'subHeading' },
    { labelKey: 'Start Date', value: 'startDate' },
    { labelKey: 'End Date', value: 'endDate' },
    { labelKey: 'Image Url', value: 'imageUrl' },
    // { labelKey: 'Prize Amount Gc', value: 'prizeAmountGc' },
    // { labelKey: 'Prize Amount Sc', value: 'prizeAmountSc' },
    { labelKey: 'Wager Base Amount', value: 'wagerBaseAmt' },
    // { labelKey: 'Wager Base Amount Type', value: 'wagerBaseAmtType' },
    { labelKey: 'Status', value: 'status' },
    { labelKey: 'IsActive', value: 'isActive' },
    { labelKey: 'Action', value: 'Action' },
]