import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllTierUserListParams} from '../../../utils/apiCalls'
import { useState } from 'react'

const useTierUserDetails = () => {
  const { tierId } = useParams()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('userId')
  const [sort, setSort] = useState('DESC')
  const [over, setOver] = useState(false)


  const { data: tierUserData, isLoading:isUserDataLoading } = useQuery({
    queryKey: ['tierUser',limit, page, orderBy, sort, ],
    queryFn: ({ queryKey }) => {
      const params = {pageNo: queryKey[2], limit: queryKey[1]};
      if (queryKey[3]) params.orderBy = queryKey[3]
      if (queryKey[4]) params.sort = queryKey[4]
      return getAllTierUserListParams({tierId,params})
    },
    select: (res) => res?.data?.tierUserDetail,
    refetchOnWindowFocus: false
  })


  const selected = (h) =>
    orderBy === h.value &&
    h.labelKey !== 'Actions'

  const totalPages = Math.ceil(tierUserData?.count / limit)
  return {
    navigate,
    tierUserData,
    isUserDataLoading,
    sort,
    setSort,
    setOrderBy,
    setLimit,
    setPage,
    limit,
    page,
    over,
    setOver,
    totalPages,
    selected
  }
}

export default useTierUserDetails