import React, { useState } from "react";

import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner,
  Modal,
  ProgressBar,
  Tabs,
  Tab,
} from "@themesberg/react-bootstrap";

import {
  formatDateMDY,
  formatDateYMD,
  getDateTime,
  getDateTimeByYMD,
} from "../../../utils/dateFormatter";
import Datetime from "react-datetime";
import { serialize } from "object-to-formdata";
import { toast } from "../../../components/Toast";
import { errorHandler, useCreateRaffleMutation } from "../../../reactQuery/hooks/customMutationHook/index.js";
import Trigger from "../../../components/OverlayTrigger/index.jsx";
import { AdminRoutes } from "../../../routes.js";
import { useNavigate } from "react-router-dom";
import { editRaffleSchema } from "../schemas.js";

const CreateRaffle = ({ data }) => {
  const navigate = useNavigate();
  const yesterday = new Date(Date.now());
  const { mutate: createRaffle, isLoading: createLoading } =
    useCreateRaffleMutation({
      onSuccess: (res) => {
        toast("Raffle Created Successfully", "success");
        navigate(AdminRoutes.Raffle);
      },
      onError: (error) => {
        toast(error.response.data.errors[0].description, "error");
        errorHandler(error);
      },
    });

  const handleCreatePackageSubmit = (formValues) => {
    const body = {
      ...formValues,
      title: formValues.title,
      subHeading: formValues.subHeading,
      description: formValues.description,
      wagerBaseAmtType: formValues.wagerBaseAmtType,
      wagerBaseAmt: formValues.wagerBaseAmt,
      startDate: formatDateYMD(formValues.startDate),
      endDate: formatDateYMD(formValues.endDate),
      prizeAmountGc: formValues.prizeAmountGc,
      prizeAmountSc: formValues.prizeAmountSc,
      isActive: formValues.isActive,
      bannerImg: formValues.bannerImg,
      termsAndConditions: formValues.termsAndConditions,
    };
    createRaffle(serialize(body));
  };
  return (
    <div>
      <Row>
        <Col sm={8}>
          <h3>Create Giveaways</h3>
        </Col>
      </Row>

      <Formik
        initialValues={{
          title: "",
          subHeading: "",
          description: "",
          wagerBaseAmt: "",
          wagerBaseAmtType: 'SC',
          startDate: new Date(Date.now() + 86400000),
          endDate: new Date(Date.now() + 86400000),
          prizeAmountGc: 0,
          prizeAmountSc: 0,
          isActive: false,
          bannerImg: "",
          termsAndConditions: "",
        }}
        validationSchema={editRaffleSchema}
        onSubmit={handleCreatePackageSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <Row>
              <Col>
                <BForm.Label>Title</BForm.Label>

                <BForm.Control
                  type="text"
                  name="title"
                  min="0"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component="div"
                  name="title"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>Sub Heading</BForm.Label>

                <BForm.Control
                  type="text"
                  name="subHeading"
                  min="0"
                  value={values.subHeading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component="div"
                  name="subHeading"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>Description</BForm.Label>

                <BForm.Control
                  type="text"
                  name="description"
                  min="0"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component="div"
                  name="description"
                  className="text-danger"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>

                <BForm.Label >
                  Wagerbase Amount Type
                  <span className='text-danger'> *</span>
                </BForm.Label>
                <BForm.Select
                  type='text'
                  name={'wagerBaseAmtType'}
                  value={values.wagerBaseAmtType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >

                  {
                    <>
                      <option key={'SC'} value={'SC'}>
                        SC
                      </option>
                      <option key={'GC'} value={'GC'}>
                        GC
                      </option>
                    </>

                  }
                </BForm.Select>
              </Col>
              <Col>
                <BForm.Label>Wager Base Amount</BForm.Label>

                <BForm.Control
                  type="number"
                  name="wagerBaseAmt"
                  min="0"
                  value={values.wagerBaseAmt}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component="div"
                  name="wagerBaseAmt"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>Prize Amount GC</BForm.Label>

                <BForm.Control
                  type="number"
                  name="prizeAmountGc"
                  min="0"
                  value={values.prizeAmountGc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component="div"
                  name="prizeAmountGc"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>Prize Amount SC</BForm.Label>

                <BForm.Control
                  type="number"
                  name="prizeAmountSc"
                  min="0"
                  value={values.prizeAmountSc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component="div"
                  name="prizeAmountSc"
                  className="text-danger"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <BForm.Label>
                  StartDate
                  <span className="text-danger"> *</span>
                </BForm.Label>
                <Datetime
                  inputProps={{
                    placeholder: "MM-DD-YYYY HH:MM",
                    disabled: false,
                  }}
                  dateFormat="MM/DD/YYYY"
                  onChange={(e) => {
                    setFieldValue("startDate", e);
                  }}
                  value={values.startDate}
                  isValidDate={(e) => {
                    return (
                      e._d > yesterday ||
                      getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())
                    );
                  }}
                  timeFormat={false}
                />
                <ErrorMessage
                  component="div"
                  name="startDate"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>
                  End Date
                  <span className="text-danger"> *</span>
                </BForm.Label>
                <Datetime
                  inputProps={{
                    placeholder: "MM-DD-YYYY HH:MM",
                    disabled: false,
                  }}
                  dateFormat="MM/DD/YYYY"
                  onChange={(e) => {
                    setFieldValue("endDate", e);
                  }}
                  value={values.endDate}
                  isValidDate={(e) => {
                    return (
                      e._d > yesterday ||
                      getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())
                    );
                  }}
                  timeFormat={false}
                />
                <ErrorMessage
                  component="div"
                  name="endDate"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>Active</BForm.Label>
                <BForm.Check
                  type="switch"
                  name="isActive"
                  checked={values.isActive}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  component="div"
                  name="isActive"
                  className="text-danger"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <BForm.Label>Terms & Condition</BForm.Label>

                <BForm.Control
                  type="text"
                  name="termsAndConditions"
                  min="0"
                  value={values.termsAndConditions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component="div"
                  name="termsAndConditions"
                  className="text-danger"
                />
              </Col>
              <Col md={6} sm={12} className="mt-3 d-grid">
                <BForm.Label>Banner Image</BForm.Label>

                <BForm.Text>
                  <Trigger message="message" id={"mes"} />
                  <input
                    id={"mes"}
                    title=" "
                    name="bannerImg"
                    type="file"
                    onChange={(event) => {
                      setFieldValue("bannerImg", event.currentTarget.files[0]);
                    }}
                  />
                  {values?.bannerImg && (
                    <img
                      alt="not found"
                      width="100px"
                      src={URL.createObjectURL(values.bannerImg)}
                    />
                  )}
                  {/* {!values?.image && packageData?.imageUrl && (
                                        <img alt='not found' width='60px' src={packageData.imageUrl} />
                                    )} */}
                </BForm.Text>

                <ErrorMessage
                  component="div"
                  name="image"
                  className="text-danger"
                />
              </Col>
            </Row>

            <div className="mt-4 d-flex justify-content-between align-items-center">
              <Button
                variant="warning"
                onClick={() => navigate(AdminRoutes.Raffle)}
              >
                Cancel
              </Button>

              <Button
                variant="success"
                onClick={() => {
                  handleSubmit();
                }}
                className="ml-2"
                disabled={createLoading}
              >
                Submit
                {createLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateRaffle;
