import React, { useEffect, useState } from "react";
import { hoursFilterOptions, playerTypeOptions } from "./constants";
import { Row, Col, Form, Button } from "@themesberg/react-bootstrap";
import {
  formatDateYMD,
  getDateThreeMonthsBefore,
} from "../../utils/dateFormatter";
import DateRangePicker from "../../components/DateRangePicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertTimeZone } from "../../utils/helper";
import Datetime from "react-datetime";

const DashBoardFilters = ({
  setPlayerType,
  playerType,
  t,
  timeZoneCode,
  reportRefetch,
  customerRefetch,
  transactionRefetch,
  economyRefetch,
  dashboardReportRefetch,
  economicDataAccordionOpen,
  transactionDataAccordianOpen,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const handleSearch = () => {
    reportRefetch();
    customerRefetch();
    dashboardReportRefetch();
    transactionDataAccordianOpen && transactionRefetch();
    economicDataAccordionOpen && economyRefetch();
  };

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        alignContent: "center",
      }}
    >
      <Col className="col-lg-2 col-sm-6 col-12">
        <Form.Label>{t("filter.playerType.title")}</Form.Label>

        <Form.Select
          value={playerType}
          onChange={(event) => {
            setPlayerType(event.target.value);
          }}
        >
          {playerTypeOptions?.map(({ labelKey, value }) => {
            return (
              <option key={value} value={value}>
                {t(labelKey)}
              </option>
            );
          })}
        </Form.Select>
      </Col>
      <Col
        className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
        style={{ width: "250px" }}
      >
        <Form.Label column="sm" className="mx-auto text-nowrap px-2">
          Start Date
        </Form.Label>
        <Datetime
          value={startDate}
          onChange={(date) => setStartDate(date)}
          timeFormat={false}
        />
      </Col>
      <Col
        className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
        style={{ width: "250px" }}
      >
        <Form.Label column="sm" className="mx-auto text-nowrap px-2">
          End Date
        </Form.Label>
        <Datetime value={endDate} onChange={(date) => setEndDate(date)}   timeFormat={false}/>
      </Col>
      <Col className="col-lg-2 col-sm-6 col-12 d-flex align-items-end mt-3 mt-sm-0">
        <Button variant="outline-secondary" onClick={handleSearch}>
          Search
        </Button>
      </Col>
    </Row>
  );
};

export default DashBoardFilters;
