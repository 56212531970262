import React from 'react'

import { Formik, Form, ErrorMessage, FieldArray } from 'formik'
import {
    Col,
    Row,
    Form as BForm,
    Button,
    Spinner,
    Modal,
    ProgressBar,
    Tabs,
    Tab,
    Table
} from '@themesberg/react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import { getRaffleDetail } from '../../../utils/apiCalls';
import { useQuery } from '@tanstack/react-query';
import { formatDateMDY } from '../../../utils/dateFormatter';


const ViewRaffle = ({ data }) => {
    const navigate = useNavigate();
    const { raffleId } = useParams();
    const { data: raffleDetail, isLoading: loading } = useQuery({
        queryFn: () => {
            return getRaffleDetail(raffleId)
        },
        select: (res) => res?.data?.getRaffleDetail,
        refetchOnWindowFocus: false
    })
    return (
        <div>
            <Row>
                <Col sm={8}>
                    <h3>View Giveaways</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} lg={3} >
                    <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                        <h6 className='mb-0 me-2'>Title</h6>
                        <span >{raffleDetail?.title}</span>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={3} >
                    <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                        <h6 className='mb-0 me-2'>Sub Heading</h6>
                        <span >{raffleDetail?.subHeading}</span>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={3} >
                    <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                        <h6 className='mb-0 me-2'>Wager Base Amount</h6>
                        <span >{raffleDetail?.wagerBaseAmt}</span>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={3} >
                    <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                        <h6 className='mb-0 me-2'>Status</h6>
                        <span >{raffleDetail?.isActive == true ? 'True' : 'False'}</span>
                    </div>
                </Col>

            </Row>
            <Row>
                <Col xs={12} md={6} lg={3} >
                    <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                        <h6 className='mb-0 me-2'>Start Date</h6>
                        <span >{formatDateMDY(raffleDetail?.startDate)}</span>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={3} >
                    <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                        <h6 className='mb-0 me-2'>End Date</h6>
                        <span >{formatDateMDY(raffleDetail?.endDate)}</span>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={3} >
                    <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                        <h6 className='mb-0 me-2'>GC Prize</h6>
                        <span >{raffleDetail?.prizeAmountGc}</span>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={3} >
                    <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                        <h6 className='mb-0 me-2'>SC Prize</h6>
                        <span >{raffleDetail?.prizeAmountSc}</span>
                    </div>
                </Col>
            </Row> {console.log(raffleDetail?.winnerObj?.userId, 'raffleDetail?.winnerObj')}
            {raffleDetail?.winnerObj?.userId != undefined ?
                <> <Row>
                    <Col xs={12} md={6} lg={3} >
                        <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                            <h6 className='mb-0 me-2'>Winner Id</h6>
                            <span >{raffleDetail?.winnerObj?.userId}</span>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3} >
                        <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                            <h6 className='mb-0 me-2'>Winner Username</h6>
                            <span >{raffleDetail?.winnerObj?.username}</span>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3} >
                        <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                            <h6 className='mb-0 me-2'>Winner Ticket Id</h6>
                            <span >{raffleDetail?.winnerObj?.winnerTicketId}</span>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3} >
                        <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
                            <h6 className='mb-0 me-2'>Win Date</h6>
                            <span >{formatDateMDY(raffleDetail?.winnerObj?.wonDate)}</span>
                        </div>
                    </Col>
                </Row>
                </>
                : <>
                </>}
            <Row className='mt-3'>
                <Col sm={8}>
                    <h5>User Detail</h5>

                    <Table bordered striped responsive hover size='sm' className='text-center mt-4' >
                        <thead>

                            <tr>
                                <th>
                                    User Id
                                </th>
                                <th>
                                    Enteries
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                raffleDetail?.userEntry?.length > 0 ?
                                    raffleDetail?.userEntry?.map((e, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {e?.userId}
                                                </td>
                                                <td>
                                                    <tr>
                                                        <th>Entry Id</th>
                                                        <th>Awarded Date</th>
                                                    </tr>
                                                    {e?.RafflesEntry?.length > 0 ?
                                                        e?.RafflesEntry?.map((i, idx) => {
                                                            return (
                                                                <tr key={idx}>
                                                                    <td>{i.entryId}</td>
                                                                    <td>{formatDateMDY(i.createdAt)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        : <></>}
                                                </td>

                                            </tr>
                                        )
                                    })

                                    : <>
                                        <tr>
                                            <td colSpan={3} className='text-danger text-center'>
                                               No Data Found
                                            </td>
                                        </tr>
                                    </>

                            }
                        </tbody>
                    </Table>


                </Col>
            </Row>
        </div>
    )
}

export default ViewRaffle
