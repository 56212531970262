import React from 'react'
import { casinoCategorySchema } from '../schemas'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner,
  Modal,
} from '@themesberg/react-bootstrap'
import useCreateTournaments from '../hooks/useCreateTournaments'
import { formatDateMDY, formatDateYMD } from '../../../utils/dateFormatter'
import Datetime from 'react-datetime';
import useTournamentDetails from '../hooks/useTournamentDetails'
import CreateTournament from './CreateTournament'
import Preloader from '../../../components/Preloader'

const EditTournament = () => {
  const {  tournamentData,
    loading,
    navigate } = useTournamentDetails()
  if(loading) return <Preloader />
  return <CreateTournament data={tournamentData}  details/>
}

export default EditTournament
