import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import {
  faUserAlt,
  faAward,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { loginCountSocket } from "../../../../utils/socket";
import { useUserStore } from "../../../../store/store";

export const Ticker = ({ loginData }) => {
  const [loginCount, setLoginCount] = useState(0);
  const [livePlayersCount, setLivePlayersCount] = useState(0);
  const labelList = loginData.find(
    (data) => data?.label === "loginKeys.CURRENT_LOGIN"
  ).total;

  const loginCountSocketConnection = useUserStore(
    (state) => state.loginCountSocketConnection
  );
  const livePlayersCountConnection = useUserStore(
    (state) => state.livePlayersCountConnection
  );

  const loginCountSocketData = (data) => {
    setLoginCount(data);
  };

  const livePlayersCountSocketData = (data) => {
    setLivePlayersCount(data);
  };

  useEffect(() => {
    if (!loginCountSocketConnection) return;
    loginCountSocket.on("LIVE_USER_COUNT", (data) => {
      loginCountSocketData(data?.currentUserCount);
    });
    return () => {
      loginCountSocket.off("LIVE_USER_COUNT", () => {
        console.log("socket disconnected");
      });
    };
  }, [loginCountSocketConnection]);

  useEffect(() => {
    if (!livePlayersCountConnection) return;
    loginCountSocket.on("LIVE_GAME_PLAY_COUNT", (data) => {
      livePlayersCountSocketData(data?.currentUserCount);
    });
    return () => {
      loginCountSocket.off("LIVE_GAME_PLAY_COUNT", () => {
        console.log("socket disconnected");
      });
    };
  }, [livePlayersCountConnection]);

  return (
    <div className="ticker-wrapper">

      <div className="ticker-container">
        <div className="ticker-today-loginC">
          <div className="new-icon">
            <FontAwesomeIcon icon={faUserAlt} style={{ color: "black" }} />
          </div>
          <h5>Online Players</h5>
          <div className="ticket-todayC">
            {" "}
            <p>{loginCount ? loginCount : labelList}</p>
            {/* <p className="green-c">{" (+50%) "}</p> */}
            {/* <FontAwesomeIcon icon={faArrowUp} style={{fontSize:"25px",color:"green"}} /> */}
          </div>
        </div>
      </div>
      <div className="ticker-container">
        <div className="ticker-today-loginC">
          <div className="new-icon">
            <FontAwesomeIcon icon={faUserAlt} style={{ color: "black" }} />
          </div>
          <h5>Active Players</h5>
          <div className="ticket-todayC">
            {" "}
            <p>{livePlayersCount ? livePlayersCount : labelList}</p>
          
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Ticker;
