import React from 'react'
import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup,
  Form
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PaginationComponent from '../../../components/Pagination'
import Trigger from '../../../components/OverlayTrigger'
import Preloader, { InlineLoader } from '../../../components/Preloader'
import useCheckPermission from '../../../utils/checkPermission'
import { leaderTableHeaders } from '../constants'
import { useTranslation } from 'react-i18next'


const LeaderBoard = ({ list }) => {
  const { isHidden } = useCheckPermission()
  const { t } = useTranslation(['tournaments'])
  // if(loading) return (<Preloader />)
  return (
    <>
      <>


        {/* <Row className='mb-3 w-100 m-auto'> */}
        {/* <Col xs='12' lg='auto'>
            <div className='d-flex justify-content-start align-items-center w-100 mb-2 flex-wrap'>
              <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('casinoSubCategory.filters.category')}
              </Form.Label>

              <Form.Select
                value={categoryFilter}
                onChange={(e) => {
                  setPage(1)
                  setCategoryFilter(e.target.value)
                }}
                style={{ minWidth: '230px' }}
              >
                <option value=''>{t('casinoSubCategory.filters.all')}</option>

                {casinoCategories && casinoCategories?.rows?.map((c) => (
                  <option key={c?.masterGameCategoryId} value={c?.masterGameCategoryId}>{c?.name?.EN}</option>
                ))}
              </Form.Select>
            </div>
          </Col> */}
        {/* <Col xs='12' lg='auto' className='mt-2 mt-lg-0'>
            <div className='d-flex justify-content-start align-items-center w-100 flex-wrap'>
              <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('casinoSubCategory.filters.search')}
              </Form.Label>

              <Form.Control
                type='search'
                value={search}
                placeholder={'Search by title'}
                onChange={(event) => {
                  setPage(1)
                  setSearch(
                    event.target.value.replace(/[~`!$%@^&*#=)()><?]+/g, '')
                  )}}
                style={{ minWidth: '230px' }}
              />
            </div>
          </Col> */}
        {/* <Col xs='12' lg='auto'>
            <div className='d-flex justify-content-start align-items-center w-100 flex-wrap'>
              <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('casinoSubCategory.filters.status')}
              </Form.Label>

              <Form.Select
                onChange={(e) => { 
                  setPage(1)
                  setStatusFilter(e.target.value)
                }}
                value={statusFilter}
                style={{ minWidth: '230px' }}
              >
                <option value='all'>{t('casinoSubCategory.filters.all')}</option>
                <option value='true'>{t('casinoSubCategory.filters.active')}</option>
                <option value='false'>{t('casinoSubCategory.filters.inactive')}</option>
              </Form.Select>
            </div>
          </Col> */}





        {/* <Col xs='12' sm='6' lg='4' className='mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Time Period
          </Form.Label>
          <DateRangePicker
            width="auto"
            state={state} setState={setState}
          />
        </Col> */}
        {/* </Row> */}

        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {leaderTableHeaders.map((h, idx) => (
                <th
                  key={idx}
                >
                  {t(h.labelKey)}{' '}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {list?.length > 0 &&
              list?.map(
                ({
                  tournamentId,
                  score,
                  User,
                  scWinAmount,
                  gcWinAmount,
                  isWinner
                }, index) => {
                  return (
                    <tr key={tournamentId}>
                      <td>{index + 1}</td>
                      {/* <td>{tournamentId}</td> */}
                      <td>
                        <Trigger message={User.username} id={User.username} />
                        <span
                          id={User.username}
                          style={{
                            width: '100px',
                            cursor: 'pointer'
                          }}
                          className='d-inline-block text-truncate'
                        >
                          {User.username}
                        </span>
                      </td>

                      <td>{User.email}</td>

                      <td>
                        {score}
                      </td>
                      <td>
                        {scWinAmount}
                      </td>
                      <td>
                        {gcWinAmount}
                      </td>
                      <td>
                        {isWinner ? "Yes" : "No"}
                      </td>
                    </tr>
                  )
                }
              )}

            {list?.length === 0 &&
              (
                <tr>
                  <td
                    colSpan={7}
                    className='text-danger text-center'
                  >
                    {t('tournaments.noDataFound')}
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
        {/* {loading && <InlineLoader />}
        {list?.count !== 0 &&
          (
            <PaginationComponent
              page={list?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )} */}
      </>

    </>
  )
}

export default LeaderBoard