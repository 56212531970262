import { QueryClient } from '@tanstack/react-query'

const queryClient = new QueryClient({
  // defaultOptions: {
  //   queries: {
  //     staleTime: Infinity
  //   }
  // }
})

export default queryClient
