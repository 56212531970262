import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import { Row, Col, Form as BForm, Button } from '@themesberg/react-bootstrap'
import { PlayerSearchContainer } from './style'
import { playerSearchSchmes } from './schemas'
import { checkForReset, initialSet } from './constants'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import Trigger from '../../components/OverlayTrigger'
import { onDownloadCsvClick } from '../../utils/helper'

const PlayerSearch = (props) => {
  const {
    globalSearch,
    setGlobalSearch,
    getCsvDownloadUrl
  } = props
  const resetToggler = (resetForm) => {
    resetForm()
    setGlobalSearch(initialSet)
  }
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const handleDownloadClick = async () => {
    try {
      setDownloadInProgress(true);
      const url = getCsvDownloadUrl();
      await onDownloadCsvClick(url, 'Player_Details')
    } catch (error) {
      console.error('Error downloading CSV:', error);
    } finally {
      setDownloadInProgress(false);
    }
  };

  return (
    <PlayerSearchContainer>
      <Formik
        initialValues={{
          idSearch: '',
          emailSearch: '',
          firstNameSearch: '',
          lastNameSearch: '',
          userNameSearch: '',
          affiliateIdSearch: '',
        }}
        validationSchema={playerSearchSchmes()}
        onSubmit={(formValues, { resetForm }) => {
          const tempValue = { ...formValues }
          setGlobalSearch(tempValue)
        }}
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          resetForm,
          setFieldValue
        }) => (
          <Form>
            <Row>
              <Col className='col-lg-3 col-sm-6 col-12'>
                <BForm.Group className='mb-3' controlId='idSearch'>
                  <BForm.Label>Player Id</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='idSearch'
                    placeholder='Player ID'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.idSearch}
                  />
                  <ErrorMessage
                    component='div'
                    name='idSearch'
                    className='text-danger'
                  />
                </BForm.Group>
              </Col>
              <Col className='col-lg-3 col-sm-6 col-12'>
                <BForm.Group className='mb-3' controlId='formGroupEmail'>
                  <BForm.Label>Email</BForm.Label>
                  <BForm.Control
                    type='text'
                    placeholder='Email'
                    name='emailSearch'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailSearch}
                  />
                  <ErrorMessage
                    component='div'
                    name='emailSearch'
                    className='text-danger'
                  />
                </BForm.Group>
              </Col>
              <Col className='col-lg-3 col-sm-6 col-12'>
                <BForm.Group className='mb-3' controlId='formGroupEmail'>
                  <BForm.Label>First Name</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='firstNameSearch'
                    placeholder='First Name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstNameSearch}
                  />
                </BForm.Group>
              </Col>
              <Col className='col-lg-3 col-sm-6 col-12'>
                <BForm.Group className='mb-3' controlId='formGroupEmail'>
                  <BForm.Label>Last Name</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='lastNameSearch'
                    placeholder='Last Name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastNameSearch}
                  />
                </BForm.Group>
              </Col>
            </Row>
            <Row>
              <Col className='col-lg-3 col-sm-6 col-12'>
                <BForm.Group className='mb-3' controlId='formGroupEmail'>
                  <BForm.Label>User Name</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='userNameSearch'
                    placeholder='UserName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userNameSearch}
                  />
                </BForm.Group>
              </Col>

            </Row>
            <Row>
              <Col>
                <div className='buttonDiv'>
                  <Button variant='primary' type='submit'>Search</Button>
                  <div>
                    <Button variant='secondary' onClick={() => resetToggler(resetForm)} type='button'>Reset</Button>

                    <Trigger message='Download as CSV' id={'csv'} />
                    <Button
                      id={'csv'}
                      variant='success' style={{ marginLeft: '10px' }}
                      onClick={handleDownloadClick}
                    >
                     {downloadInProgress ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : (
              <FontAwesomeIcon icon={faFileDownload} />
              )}
                    </Button>
                  </div>

                </div>
              </Col>
            </Row>
          </Form>)}
      </Formik>
    </PlayerSearchContainer>
  )
}

export default PlayerSearch;