import React, { useState } from 'react';
import { Row, Col } from '@themesberg/react-bootstrap';
import { Formik } from 'formik';
import { format } from 'date-fns';
import { createPackageSchema } from '../schemas'
import { useNavigate } from 'react-router-dom';
import PackageCreateForm from './PackageCreateForm';
import usCreatePackage from '../hooks/useCreatePackage';
import { useCreatePackageMutation } from '../../../reactQuery/hooks/customMutationHook';
import { AdminRoutes } from '../../../routes';
import { toast } from '../../../components/Toast';
import { useTranslation } from 'react-i18next';
import { serialize } from 'object-to-formdata';
import { getDateTime } from '../../../utils/dateFormatter';

const CreatePackages = () => {
  const {
    // isGetPackageTypeLoading,
    typeOptions,
    typeValue,
    setTypeValue,
    isSelectLoading,
    handleCreateOption,
  } = usCreatePackage();
  const { t } = useTranslation(['packages']);
  const navigate = useNavigate();
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const onSuccess = (res) => {
    if (res?.data?.success) {
      toast(res?.data?.message, 'success', 'packageCreate');
      navigate(AdminRoutes.Packages);
    } else {
      toast(res?.data?.message, 'error', 'packageCreate');
    }
  };
  const onError = (error) => {
    toast(error.response.data.errors[0].description, 'error', 'packageCreate');
  };
  const { mutate: createPackage, isLoading } = useCreatePackageMutation({
    onSuccess,
    onError,
  });

  const handleCreatePackageSubmit = (formValues) => {
    const packageTypeInfo = typeOptions.find(
      (item) => item.value === formValues.packageType
    );
    const body = {
      ...formValues,
      amount: formValues.amount.toString(),
      isActive: formValues.isActive,
      packageType: formValues.packageType,
      currency: 'USD',
      isVisibleInStore: formValues.isVisibleInStore,
      image: formValues.image,
      validTill: formValues.isValidUntil ? getDateTime(formValues.validTill) : '',
      newPackageType: false,
      showPackageType: formValues.showPackageType,
      playerIds: selectedUserIds,
      validFrom: formValues.isValidFrom ? (formValues.validFrom ? getDateTime(formValues.validFrom) : null) : null,
      filterType: selectedUserIds?.length > 0 ? (formValues?.filterType) : null,
      filterOperator: selectedUserIds?.length > 0 ? (formValues?.filterOperator) : null,
      filterValue: selectedUserIds?.length > 0 ? (Number(formValues?.filterValue)) : null,
      isSpecialPackage:formValues.isSpecialPackage
    };
    if (packageTypeInfo?.newOptions) {
      body.newPackageType = true;
    }
    // format(formValues.validTill, parse(new Date(formValues.validTill)), 'dd-MM-yyyy')
    createPackage(serialize(body));
  };

  return (
    <div>
      <Row>
        <Col sm={8}>
          <h3>{t('createPackage.title')}</h3>
        </Col>
      </Row>

      <Formik
        initialValues={{
          amount: '',
          gcCoin: '',
          scCoin: '',
          isActive: false,
          isValidUntil: false,
          packageType: null,
          isVisibleInStore: false,
          image: '',
          validTill: null,
          textColor: '',
          showPackageType: true,
          backgroundColor: '',
          previousAmount: '',
          firstPurchaseApplicable: false,
          firstPurchaseScBonus: null,
          firstPurchaseGcBonus: null,
          welcomePurchaseBonusApplicable: false,
          welcomePurchaseBonusApplicableMinutes: null,
          purchaseLimitPerUser: null,
          welcomePurchasePercentage: 0,
          isValidFrom: false,
          validFrom: null,
          bonusSc: 0,
          bonusGc: 0,
          filterType: selectedUserIds?.length > 0 || null,
          filterOperator: selectedUserIds?.length > 0 || null,
          filterValue: selectedUserIds?.length > 0 || null,
          isSpecialPackage:false
        }}
        validationSchema={createPackageSchema}
        onSubmit={handleCreatePackageSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <PackageCreateForm
            values={values}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            loading={isLoading}
            navigate={navigate}
            typeOptions={typeOptions}
            typeValue={typeValue}
            setTypeValue={setTypeValue}
            isSelectLoading={isSelectLoading}
            handleCreateOption={handleCreateOption}
            selectedUserIds={selectedUserIds}
            setSelectedUserIds={setSelectedUserIds}
          />
        )}
      </Formik>
    </div>
  );
};

export default CreatePackages;
