import React from 'react'

const AffiliateTransitions = () => {
  return (
    <div>
     Transitions
    </div>
  )
}

export default AffiliateTransitions
