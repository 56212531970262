import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllTournamentsListParams} from '../../../utils/apiCalls'

const useTournamentDetails = () => {
  const { tournamentId } = useParams()
  const navigate = useNavigate()

  const { data: tournamentData, isLoading: loading } = useQuery({
    queryKey: ['tournamentId', tournamentId ],
    queryFn: ({ queryKey }) => {
      const params = {};
      return getAllTournamentsListParams({tournamentId})
    },
    select: (res) => res?.data?.data,
    refetchOnWindowFocus: false
  })
  return {
    tournamentData,
    loading,
    navigate
  }
}

export default useTournamentDetails