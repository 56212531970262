export const tableHeaders = [
    { labelKey: 'Promocode Id', value: 'promocodeId' },
    { labelKey: 'Promocode', value: 'promocode' },
    { labelKey: 'Affiliate Id', value: 'affiliateId' },
    { labelKey: 'Bonus Sc', value: 'bonusSc' },
    { labelKey: 'Bonus Gc', value: 'bonusGc' },
    { labelKey: 'Valid Till', value: 'validTill' },
    { labelKey: 'Is Active', value: 'isActive' },
    { labelKey: 'Action', value: 'Action' },
]

export const viewPromocodeHeaders = [
    { labelKey: 'User Id', value: 'userId' },
    { labelKey: 'Username', value: 'username' },
    { labelKey: 'Email', value: 'email' },
    { labelKey: 'Bonus Sc', value: 'scAmount' },
    { labelKey: 'Bonus Gc', value: 'gcAmount' },
    { labelKey: 'Claimed At', value: 'claimedAt' },
]